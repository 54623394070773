window.agregaProductoOnline = function(selected) {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var idProductoAgregar = selected;
	fetch('/agregaProductoCarritoOnline?cart=' + identificadorCarrito + "&idProducto=" + idProductoAgregar,{method:'get'})
		.then(response => response.text())
		.then(html     => { document.getElementById("div_prod_" + idProductoAgregar).innerHTML = html})
};

window.vistaindividualOnline = function(selected) {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var idProductoAgregar = selected;
	window.location.href = '/vistaindividualOnline?cart=' + identificadorCarrito + "&idProducto=" + idProductoAgregar;
};

window.verCategoria = function(selected, idTienda) {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var idCategoria = selected;
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/despliegaCategoria?cart=' + identificadorCarrito + '&idTienda=' + idTienda + "&idCategoria=" + idCategoria + '&widhtg=' + widhtg + "&heigth=" + heigth;
};

window.categoriaVendedor = function(selected, idTienda) {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var idCategoria = selected;
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/categoriaVendedor?cart=' + identificadorCarrito + '&idTienda=' + idTienda + "&idCategoria=" + idCategoria + '&widhtg=' + widhtg + "&heigth=" + heigth;
};

window.desplegarCategorias = function() {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location.href = '/listadoCategorias?cart=' + identificadorCarrito + '&widhtg=' + widhtg + "&heigth=" + heigth;
};

window.desplegarCategoriasVendedor = function() {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location.href = '/categoriasListado?cart=' + identificadorCarrito + '&widhtg=' + widhtg + "&heigth=" + heigth;
};

window.vistaindividualCategoria = function() {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var categoria = document.getElementById('idCategoria').value;
	var idTienda = document.getElementById('idTienda').value;
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/despliegaCategoria?cart=' + identificadorCarrito + '&idTienda=' + idTienda + "&idCategoria=" + categoria + '&widhtg=' + widhtg + "&heigth=" + heigth;
};

window.categoriaIndividualVendedor = function() {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var categoria = document.getElementById('idCategoria').value;
	var idTienda = document.getElementById('idTienda').value;
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/despliegaCategoria?cart=' + identificadorCarrito + '&idTienda=' + idTienda + "&idCategoria=" + categoria + '&widhtg=' + widhtg + "&heigth=" + heigth;
};